<template>
  <section class="oepn_invoice">
    <NavBar />
    <Header type="personalCenter" />
    <div class="content box pb30">
      <div class="current_position mt30">当前位置：发票中心-开票信息</div>
      <div class="title mt30">编辑发票信息</div>
      <div class="warning mt30 p030 frfsc">
        <div class="icon mr15">
          <img src="~assets/images/warning.png">
        </div>
        <div class="text">请确认核实，确保提供的发票信息与在税务机关预留的登记信息保持一致，以免影响发票的后续使用。</div>
      </div>
      <el-form class="mt50" ref="form" label-width="33%" :model="formData.emInvoice" :rules="formRules">
        <el-form-item label="抬头类型">
          <el-radio-group v-model="formData.emInvoice.inviType">
            <el-radio :label="1">企业</el-radio>
            <el-radio :label="0">个人或事业单位</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="发票抬头" prop="invTitle">
          <el-input class="input500" type="text" v-model="formData.emInvoice.invTitle" placeholder="请输入发票抬头"></el-input>
        </el-form-item>
        <el-form-item label="纳税人识别号" prop="invIdNo" v-if="formData.emInvoice.inviType === 1">
          <el-input class="input500" type="text" v-model="formData.emInvoice.invIdNo" placeholder="请输入纳税人识别号"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="linkMan">
          <el-input class="input500" type="text" v-model="formData.emInvoice.linkMan" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="linkTel">
          <el-input class="input500" type="text" v-model="formData.emInvoice.linkTel" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="邮箱地址" prop="cusMail">
          <el-input class="input500" type="text" v-model="formData.emInvoice.cusMail" placeholder="请输入邮箱地址"></el-input>
        </el-form-item>
        <el-form-item label="开票总金额">
          <el-input class="input500" type="text" v-model="formData.emInvoice.invMoney" disabled></el-input>
        </el-form-item>
        <el-form-item label="地址" v-if="formData.emInvoice.inviType === 1">
          <el-input class="input500" type="text" v-model="formData.emInvoice.linkAddress" placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item label="电话" v-if="formData.emInvoice.inviType === 1">
          <el-input class="input500" type="text" v-model="formData.emInvoice.tel" placeholder="请输入电话"></el-input>
        </el-form-item>
        <el-form-item label="开户行" v-if="formData.emInvoice.inviType === 1">
          <el-input class="input500" type="text" v-model="formData.emInvoice.bankName" placeholder="请输入开户行"></el-input>
        </el-form-item>
        <el-form-item label="银行账号" v-if="formData.emInvoice.inviType === 1">
          <el-input class="input500" type="text" v-model="formData.emInvoice.bankAccount" placeholder="请输入银行账号"></el-input>
        </el-form-item>
        <el-form-item label="发票备注" v-if="formData.emInvoice.inviType === 1">
          <el-input class="input500" type="text" v-model="formData.emInvoice.userRemark" placeholder="请输入发票备注"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="submit" @click="submit">确认提交</div>
        </el-form-item>
      </el-form>
    </div>

    <SuccessPopupWindow :visible.sync="successVisible" />
    <ConfirmPopupWindow :visible.sync="confirmVisible" @confirm="confirmHandle" />
  </section>
</template>

<script>
  import NavBar from 'components/NavBar/index.vue';
  import Header from 'components/Header/index.vue';
  import SuccessPopupWindow from './components/SuccessPopupWindow.vue';
  import ConfirmPopupWindow from './components/ConfirmPopupWindow.vue';
  import {
    enterpriseOpenInvoice
  } from 'api/personalCenter.js';

  export default {
    components: {
      NavBar,
      Header,
      SuccessPopupWindow,
      ConfirmPopupWindow
    },

    created() {
      this.formData.emInvoice.companyCode = this.$route.params.companyCode;
      this.formData.emInvoice.invMoney = this.$route.params.invoiceTotalAmount;
      this.formData.emInvoiceDetails = this.$route.params.emInvoiceDetails;
      this.formData.emInvoice.companyCode = this.formData.emInvoiceDetails.reduce((a, b) => a.companyCode + ',' + b.companyCode, '');
    },

    data() {
      return {
        formData: {
          emInvoice: {
            inviType: 1,
            orderType: 'online_buy',
            invBank: '',
            companyCode: '',
            invTitle: '',
            invIdNo: '',
            linkMan: '',
            linkTel: '',
            cusMail: '',
            invMoney: '',
            linkAddress: '',
            tel: '',
            bankName: '',
            bankAccount: '',
            userRemark: ''
          }
        },
        formRules: {
          invTitle: [{required: true, message: '请输入', trigger: 'blur'}],
          invIdNo: [{required: true, message: '请输入', trigger: 'blur'}],
          linkMan: [{required: true, message: '请输入', trigger: 'blur'}],
          linkTel: [{required: true, message: '请输入', trigger: 'blur'}],
          cusMail: [{required: true, message: '请输入', trigger: 'blur'}]
        },
        successVisible: false,
        confirmVisible: false
      }
    },

    methods: {
      submit() {
        this.$refs.form.validate(valid => {
          if(!valid) {
            return false;
          }
          this.confirmVisible = true;
        });
      },
      confirmHandle() {
        this.formData.emInvoice.invBank = this.formData.emInvoice.bankName + ' ' + this.formData.emInvoice.bankAccount;
        enterpriseOpenInvoice(this.formData).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.successVisible = true;
        });
      }
    }
  }
</script>

<style lang="less" scoped>
  .oepn_invoice {
    .content {
      .current_position {
        font-size: var(--fontSize16);
        color: var(--color000);
        font-weight: 400;
      }
      .title {
        font-size: var(--fontSize16);
        color: var(--color000);
        font-weight: bold;
      }
      .warning {
        width: 100%;
        height: 48px;
        background: var(--colorfffaec);
        .icon {
          width: 28px;
          height: 28px;
        }
        .text {
          font-size: var(--fontSize16);
          color: var(--colored6d00);
          font-weight: 500;
        }
      }
      .submit {
        margin: 0 auto;
        width: 200px;
        height: 50px;
        background: var(--colored6d00);
        border-radius: 8px;
        font-size: var(--fontSize16);
        color: var(--colorfff);
        font-weight: bold;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
      }
    }
  }
</style>
