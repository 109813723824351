<template>
  <section class="confirm_popup_window">
    <el-dialog :visible="visible" :show-close="false" width="20%">
      <div>即将提交开票申请<br>请确认各项信息是否填写无误</div>
      <p class="mt15">若因信息错误导致需要重开发票的<br>需由您承担相关损失</p>
      <div class="operation frcc mt30">
        <div class="mr15" @click="cancelClick">我再看看</div>
        <div @click="confirmClick">我已确认</div>
      </div>
    </el-dialog>
  </section>
</template>

<script>
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      cancelClick() {
        this.$emit('update:visible', false);
      },
      confirmClick() {
        this.$emit('confirm');
        this.cancelClick();
      }
    }
  }
</script>

<style lang="less" scoped>
  .confirm_popup_window {
    div {
      font-size: var(--fontSize20);
      color: var(--color000);
      font-weight: bold;
      text-align: center;
    }
    p {
      font-size: var(--fontSize12);
      color: var(--color666);
      font-weight: 500;
      text-align: center;
    }
    .operation {
      div {
        width: 120px;
        height: 40px;
        border-radius: 8px;
        font-size: var(--fontSize16);
        text-align: center;
        cursor: pointer;
      }
      :nth-child(1) {
        border: 1px solid var(--colorccc);
        background: var(--colorfff);
        color: var(--color666);
        font-weight: 500;
        line-height: 38px;
      }
      :nth-child(2) {
        background: var(--colored6d00);
        color: var(--colorfff);
        font-weight: bold;
        line-height: 40px;
      }
    }
  }
</style>
