<template>
  <section class="success_popup_window">
    <el-dialog :visible="visible" width="20%" :show-close="false">
      <div class="icon">
        <img src="~assets/images/success.png">
      </div>
      <div class="text m150">
        提交成功
      </div>
      <div class="describe">
        我们将尽快审验开票信息，请耐心等候~
      </div>
      <div class="return" @click="returnClick">返回</div>
    </el-dialog>
  </section>
</template>

<script>
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      returnClick() {
        this.$emit('update:visible', false);
        this.$router.push({
          name: 'personalCenter',
          params: {
            level1: 1,
            level2: 0
          }
        });
      }
    }
  }
</script>

<style lang="less" scoped>
  .success_popup_window {
    .icon {
      margin: 0 auto;
      width: 46px;
      height: 45px;
    }
    .text {
      font-size: var(--fontSize16);;
      color: var(--color000);
      font-weight: bold;
      text-align: center;
    }
    .describe {
      font-size: var(--fontSize12);
      color: var(--color666);
      font-weight: 500;
      text-align: center;
    }
    .return {
      margin: 30px auto 0;
      width: 300px;
      height: 50px;
      background: var(--colored6d00);
      border-radius: 8px;
      font-size: var(--fontSize16);
      color: var(--colorfff);
      font-weight: bold;
      text-align: center;
      line-height: 50px;
      cursor: pointer;
    }
  }
</style>
